import { type ACTIVE_SITES } from "@/types/Sites";

type DeviceMap = {
  desktop: number;
  smartphone: number;
  tablet: number;
};

type SpaceIdRecord = {
  // register each site as a key
  [K in (typeof ACTIVE_SITES)[number]]: DeviceMap;
} & {
  // support index signature look up for dynamic site values
  [key: string]: DeviceMap;
};

export const SPACE_IDS: SpaceIdRecord = {
  autos: {
    desktop: 1197809981,
    smartphone: 1197809992,
    tablet: 1197809981,
  },
  health: {
    desktop: 1197812773,
    smartphone: 1197812774,
    tablet: 1197812773,
  },
  local: {
    desktop: 1197812710,
    smartphone: 1197812711,
    tablet: 1197812710,
  },
  shopping: {
    desktop: 1197812745,
    smartphone: 1197812746,
    tablet: 1197812745,
  },
  tech: {
    desktop: 1197810144,
    smartphone: 1197810145,
    tablet: 1197810144,
  },
};
