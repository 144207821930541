import { GENDER } from "@vzmi/comscore-js";
import { useEsiVars } from "@yahoo-commerce/edge";
import { type EdgeSideIncludes } from "@/configs/esi";

export const useGender = () => {
  const { gender } = useEsiVars<Pick<EdgeSideIncludes, "gender">>({
    gender: "",
  });
  switch (gender) {
    case "0":
      return GENDER.UNSPECIFIED;
    case "1":
      return GENDER.FEMALE;
    case "2":
      return GENDER.MALE;
    default:
      return GENDER.UNKNOWN;
  }
};
