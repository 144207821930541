import { type CaasArticle } from "@/types/Caas";

export function getArticleId(article: CaasArticle) {
  return article?.data?.contentMeta?.contentI13n?.uuid;
}

export function getArticleSiteSectionItems(article: CaasArticle) {
  return article?.data?.contentMeta?.siteSections?.sectionItems || [];
}

export function getClickThroughUrl(article: CaasArticle) {
  const seoMeta = article?.data?.contentMeta?.seoMeta;
  const mainEntityOfPage = article?.schema?.default?.mainEntityOfPage;
  return seoMeta?.clickThroughUrl || seoMeta?.canonicalUrl || mainEntityOfPage;
}

export const isCommerceArticle = (article: CaasArticle): boolean =>
  !!article.data?.contentMeta?.commerceArticleType;

export const isCreatorArticle = (article: CaasArticle): boolean =>
  article.data?.contentMeta?.contentIdentifiers?.isCreatorContent ?? false;

export const isLiveBlog = (article: CaasArticle): boolean =>
  !!article.data?.contentMeta?.liveBlog?.totalCount;
