/**
 * Page specific types
 */
export enum PageType {
  homepage = "homepage",
  category = "category",
  article = "article",
  subcategory = "subcategory",
}

export interface PageSearchParams {
  [name: string]: string | string[] | undefined;
}
