"use client";

import { GENDER } from "@vzmi/comscore-js";
import { useEsiVars } from "@yahoo-commerce/edge";
import { parseCookie } from "@yahoo-commerce/util";
import { useEffect, useState } from "react";
import { AGE_BUCKETS } from "@/configs/benji";
import { useGender } from "@/hooks/useGender";

const getGenderString = (gender: GENDER) => {
  switch (gender) {
    case GENDER.FEMALE:
      return "f";
    case GENDER.MALE:
      return "m";
    default:
      return "";
  }
};

interface PersonalizedAdData {
  ageBucket: number;
  ageInt: number;
  bot: string;
  hydrated: boolean;
  genderString: string;
  userConsents: string[];
  userJurisdiction: string;
}

export const useUserBenjiVars = () => {
  const gender = useGender();
  const [data, setData] = useState<PersonalizedAdData>({
    ageBucket: -1,
    ageInt: -1,
    bot: "0",
    genderString: "",
    hydrated: false,
    userConsents: [],
    userJurisdiction: "",
  });

  const { age, bot, jurisdiction } = useEsiVars({
    age: -1,
    bot: "0",
    jurisdiction: "",
  });

  useEffect(() => {
    let cancelled = false;

    import("@vzmi/acookie").then(({ default: ACookie }) => {
      if (cancelled) {
        return;
      }
      const ageInt = Number(age);
      const { A1S = "" } = parseCookie(document.cookie);
      const aCookie = new ACookie(A1S);
      const userJurisdiction = jurisdiction || aCookie?.value?.gucJurisdiction;
      const userConsents = aCookie?.value?.gucConsentTypes;
      const ageBucketIndex = AGE_BUCKETS.findIndex((range) => ageInt < range);
      const ageBucket = ageBucketIndex === -1 ? 0 : ageBucketIndex;

      const userData = {
        ageBucket,
        ageInt,
        bot,
        genderString: getGenderString(gender),
        hydrated: true,
        userConsents,
        userJurisdiction,
      };
      setData(userData);
    });

    return () => {
      cancelled = true;
    };
  }, [age, bot, gender, jurisdiction]);

  return data;
};
