import { useState, useEffect } from "react";
import { type CaasArticle } from "@/types/Caas";

export const SEAMLESS_PROGRESS = "seamless-progress";
export const SEAMLESS_SLUG_CHANGE = "seamless-slug-change";

export const dispatch = (eventName: string, payload: any) => {
  window.dispatchEvent(new CustomEvent(eventName, { detail: payload }));
};

export const dispatchSlugChange = (
  slug: string,
  index: number,
  article: any,
) => {
  dispatch(SEAMLESS_SLUG_CHANGE, { article, index, slug });
};

export const dispatchProgress = (progress: number) => {
  dispatch(SEAMLESS_PROGRESS, progress);
};

export const useEventLatestState = <S>(eventName: string, defaultState: S) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    const subscription = (e: any) => {
      setState(e.detail);
    };
    window.addEventListener(eventName, subscription);
    return () => {
      window.removeEventListener(eventName, subscription);
    };
  }, [eventName]);
  return state;
};

export const useSeamlessProgress = () =>
  useEventLatestState(SEAMLESS_PROGRESS, 0);

export const useSeamlessSlugChange = (
  initialState: {
    slug: string;
    article: CaasArticle;
    index: number;
  } | null,
) => useEventLatestState(SEAMLESS_SLUG_CHANGE, initialState);
