import { PageType } from "@/types/Page";

export const pageTypeMap = new Map<RegExp, PageType>([
  [/^\/$/, PageType.homepage],
  [/^\/(beauty|health|style|home|tech|stores|gift-ideas)\//, PageType.category], // Shopping Categories
  [/^\/(movers|roofing|painting|plumbing)\//, PageType.category], // Local Categories
  [
    /^\/(reviews-deals|audio|computing|gaming|health|home|phones|science|tvs)\//,
    PageType.category,
  ], // Tech Categories
]);
