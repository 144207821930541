"use client";

import { type Benji, type BenjiConfig } from "@vzmi/benji";
import { type DeviceSize } from "@yahoo-commerce/util";
import { useEffect } from "react";
import { MINIMUM_GUCE_CONSENT_AGE } from "@/configs/benji";
import { usePageType } from "@/hooks/usePageType";
import { useUserBenjiVars } from "@/hooks/useUserBenjiVars";
import { getBenjiConsent } from "@/lib/i13n";

type I13nConfigExpanded = BenjiConfig["i13n"] & {
  adLite: "0" | "1";
  adblock: "0" | "1";
  app: string;
  authed: "0" | "1";
  bot: string;
  gdpr: boolean;
  ssl: "0" | "1";
  time: number;
  tpConsent: boolean;
  tz: string;
};

export const I13N_KEYS_TO_CLEAR = [
  "pd",
  "pt",
  "pct",
  "lmsid",
  "lpstaid",
  "hashtag",
  "pstaid",
];

export const useBenjiConfig = ({
  benjiConfig,
  clearKeys,
  deviceSize,
  enableAdStacking,
  refreshLimit,
  taboolaSetting,
}: {
  benjiConfig?: { i13n: object };
  clearKeys?: boolean;
  deviceSize?: DeviceSize | null;
  enableAdStacking?: boolean;
  refreshLimit?: number;
  taboolaSetting?: NonNullable<BenjiConfig["setting"]>["taboolaSetting"];
}) => {
  const {
    ageBucket,
    ageInt,
    bot,
    genderString,
    hydrated: personalizedDataHydrated,
    userConsents,
    userJurisdiction,
  } = useUserBenjiVars();
  const pageType = usePageType();

  useEffect(() => {
    if (
      !deviceSize ||
      !personalizedDataHydrated ||
      (ageInt > 0 && ageInt < MINIMUM_GUCE_CONSENT_AGE)
    ) {
      return;
    }

    const url = new URL(window.location.href);

    const i13n: I13nConfigExpanded = {
      ...benjiConfig?.i13n,
      adLite: "0", // TODO YNCX-794
      adblock: "0", // TODO
      app: "", // TODO
      authed: ageInt > 0 ? "1" : "0", // Use age as a proxy for authed
      bka: String(ageBucket),
      bkg: genderString,
      // TODO "browser"
      bot,
      device: deviceSize,
      gdpr: userJurisdiction === "GDPR",
      lu: ageInt > 0 ? "1" : "0", // Use age as a proxy for authed
      // TODO "os"
      pg_name: pageType,
      ssl: window.location.href.startsWith("https:") ? "1" : "0",
      time: Date.now(),
      tpConsent:
        bot === "1" || !userConsents
          ? true
          : userConsents?.includes("THIRD_PARTY_CONTENT_EMBED"),
      tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      url: `${url.origin}${url.pathname}`,
    };

    if (!window.benji?.isReady) {
      const config: BenjiConfig = {
        ...benjiConfig,
        event: {},
        feature: {
          enableAdStacking: enableAdStacking ?? false,
          enableEdgeToEdge: true,
          enableRefreshDebounce: true,
          enableYahooPrebid: true,
        },
        i13n,
        positions: {},
        setting: {
          consent: getBenjiConsent(userJurisdiction, userConsents),
          refresh: {
            duration: 20,
            limit: refreshLimit,
            requireUserAction: false,
            sameSizeRefresh: true,
            tabFocus: {
              outOfFocusDuration: 3,
            },
          },
          renderOnStart: false,
          taboolaSetting,
          tracking: {
            debug: true,
            metrics: true,
            performance: true,
          },
        },
      };

      if (typeof window.benji?.start === "function") {
        window.benji.start(config);
      } else {
        window.benji = { config } as unknown as Benji;
      }
    } else {
      window.benji.updateI13N(i13n, clearKeys ? I13N_KEYS_TO_CLEAR : undefined);
    }
  }, [
    ageBucket,
    ageInt,
    benjiConfig,
    bot,
    clearKeys,
    deviceSize,
    enableAdStacking,
    genderString,
    pageType,
    personalizedDataHydrated,
    refreshLimit,
    taboolaSetting,
    userConsents,
    userJurisdiction,
  ]);
};
