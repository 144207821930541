"use client";

import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { pageTypeMap } from "@/configs/pageType";
import { PageType } from "@/types/Page";

/**
 * A basic hook implementation of PageType mapping using explicit configs with path regexes.
 * TODO: Replace with a rewrites/headers solution in the next.config.js that can then be reused in server and client.
 * @returns PageType string
 */
export const usePageType = () => {
  const pathName = usePathname();
  const [pageType, setPageType] = useState<PageType | undefined>(
    PageType.homepage,
  );

  useEffect(() => {
    for (const currKey of pageTypeMap.keys()) {
      if (currKey.test(pathName)) {
        setPageType(pageTypeMap.get(currKey));
        break;
      }
    }
  }, [pathName]);

  return pageType;
};
